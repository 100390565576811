import React, { useEffect } from 'react'
import Header from "../components/Header"
import "../App.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';
import Demo from '../components/Navbar';


const Healthcare = () => {
  const solutions = [
    {
      href: "/healthcare/revenue-cycle-management-rcm/",
      imgSrc: "/health (3).png",
      title: "Revenue Cycle Management (RCM)",
      description: "Your blueprint for revenue cycle excellence.",
    },
    {
      href: "/healthcare/scientific-hub-_-molecule-360-degree-predictive-analysis/",
      imgSrc: "/health (1).png",
      title: "Spotcomm’s Interoperability solution",
      description: "Process efficiency: The heartbeat of cost competitiveness",
    },
    {
      href: "",
      imgSrc: "/health (2).png",
      title: "Comprehensive solution for Medicare Advantage market",
      description: "A turnkey solution for compliance while seamlessly integrating with enterprise systems",
    },
    {
      href: "/healthcare/provider-lifecycle-management-plm/",
      imgSrc: "/health (4).png",
      title: "Spotcomm Enterprise Medicaid as a Service",
      description: "From Fictional Collaboration to Functional Collaboration.",
    }, {
      href: "",
      imgSrc: "/health (2).png",
      title: "Healthcare Business Process Solutions",
      description: "A turnkey solution for compliance while seamlessly integrating with enterprise systems",
    },
    {
      href: "/healthcare/provider-lifecycle-management-plm/",
      imgSrc: "/health (4).png",
      title: "Provider Lifecycle Management (PLM)",
      description: "From Fictional Collaboration to Functional Collaboration.",
    },
    {
      href: "",
      imgSrc: "/health (2).png",
      title: "Enrollment and Billing",
      description: "A turnkey solution for compliance while seamlessly integrating with enterprise systems",
    },
    {
      href: "/healthcare/provider-lifecycle-management-plm/",
      imgSrc: "/health (4).png",
      title: "Healthcare Insurance Exchanges",
      description: "From Fictional Collaboration to Functional Collaboration.",
    },
    {
      href: "",
      imgSrc: "/health (2).png",
      title: "Provider Credentialing",
      description: "A turnkey solution for compliance while seamlessly integrating with enterprise systems",
    },
    {
      href: "/healthcare/provider-lifecycle-management-plm/",
      imgSrc: "/health (4).png",
      title: "Revenue Cycle Management (RCM)",
      description: "From Fictional Collaboration to Functional Collaboration.",
    },
    {
      href: "",
      imgSrc: "/health (2).png",
      title: "Health Insurance Exchange (HIX)",
      description: "A turnkey solution for compliance while seamlessly integrating with enterprise systems",
    },
    {
      href: "/healthcare/provider-lifecycle-management-plm/",
      imgSrc: "/health (4).png",
      title: "Value Based Care",
      description: "From Fictional Collaboration to Functional Collaboration.",
    },
    {
      href: "",
      imgSrc: "/health (2).png",
      title: "Intelligent Document Management solution",
      description: "A turnkey solution for compliance while seamlessly integrating with enterprise systems",
    },
    {
      href: "/healthcare/provider-lifecycle-management-plm/",
      imgSrc: "/health (4).png",
      title: "Advance Healthcare Data Analytics ",
      description: "From Fictional Collaboration to Functional Collaboration.",
    },
    {
      href: "/healthcare/provider-lifecycle-management-plm/",
      imgSrc: "/health (4).png",
      title: "ICD 10 Compliance and Quality Assurance ",
      description: "From Fictional Collaboration to Functional Collaboration.",
    }
  ];
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 20, maxWidth: 991 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }
  return (
    <>
      <div>
        <Header />
        <div>
          <section id="heros" className="heros1 section dark-background">
            <img src="/healthbanner.png" alt="" />
          </section>
        </div>
        <div>
          <div className=" aem-Grid aem-Grid--12 aem-Grid--default--12 ">
            <div className="layout-container-component aem-GridColumn aem-GridColumn--default--12">
              <div className="white img-position--">
                <div className="layoutcontainer" style={{ background: "#D9D9D9" }}>
                  <div className="container ">
                    <div className="spacing" >
                      <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                        <div className="title aem-GridColumn aem-GridColumn--default--12"></div>
                        <div className="innerpage">
                          <h2 className="font-bold text-dark our-award " >
                            <span>Our <span>Expertise</span> </span>

                          </h2>

                        </div>
                      </div>
                    </div>
                    <div className="text-styling text parbase aem-GridColumn aem-GridColumn--default--12">
                      <div>
                        <div className="hyperlink">
                          <div>
                            <div>
                              <div>
                                <div className="innerpage" tabIndex={0}>
                                  <span className="content-h7-freight-text-pro"><span className="content-h3-freight-text-pro">Adopt an integrated approach for transformational healthcare delivery across the value chain</span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-styling text parbase aem-GridColumn aem-GridColumn--default--12">
                      <div>
                        <div className="hyperlink">
                          <div>
                            <div>
                              <div>
                                <div className="innerpage" tabIndex={0}>
                                  <span className="content-h6-neuzeit-grotesk">Healthcare clients who are quickly realizing that the future belongs to the fee-for-value model leverage Wipro’s Healthcare services to deliver cost-effective, high-quality care through<b>&nbsp;</b>robust systems, products and commercial models. We are uniquely positioned to do this because we work across the value chain—from Health and Human Services (HHS) to care delivery, and distribution and pharmacy benefit management (PBM). Backing our capability is the experience of settling more than $15 billion in claims, running one of the world’s largest e-governance health-related IT programs in India touching 50+ million lives, and handling more than 10 million member and provider calls annually.</span><br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>   <br />
                  </div>
                </div>   <br />
              </div>
            </div>
          </div>
        </div>



        <h2 className='h2' style={{ paddingLeft: "5%", paddingRight: "5%" }}>What <span className='' style={{ color: "#7F3E98 " }}>We Do </span> </h2>  <br />
        <div className="layout-container-component aem-GridColumn aem-GridColumn--default--12">
          <div className="bg-color-12 img-position--">
            <div className="layoutcontainer">
              <div className="withoutmargin">
                <div className="spacing">
                  <div className="aem-Grid aem-Grid--12 aem-Grid--default--12">
                    <div className="splitparsys aem-GridColumn aem-GridColumn--default--12">
                      <div className="splitparsys" style={{ backgroundColor: '' }}>
                        <div>
                          <div className="row" style={{ marginBottom: '25px', paddingLeft: "5%", paddingRight: "5%" }}>
                            {solutions.map((solution, index) => (
                              <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="aem-Grid aem-Grid--12 aem-Grid--default--12">
                                  <div className="solution-offering parbase aem-GridColumn aem-GridColumn--default--12">
                                    <div className="cq-dd-pages">
                                      <div className="wipro-solutions-squares-content">
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <a href={solution.href} className="cursor">
                                              <div className="wipro-solutions-squares-content-image  mx-5">
                                                <img alt="Healthcare" tabIndex={0} src={solution.imgSrc} className="multi-image" /> <br />
                                                <div className="wipro-solutions-squares-content-text text-akkurat-light">
                                                  <h4 className='fs-6 '>{solution.title}</h4>
                                                  <p style={{ fontFamily: 'var(--pnr) !important' }}>{solution.description}</p> <br />
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="layout-container-component aem-GridColumn aem-GridColumn--default--12">
          <div className="white img-position--">
            <div className="layoutcontainer">
              <div className="container ">
                <div className="spacing">
                  <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                    <div className="title aem-GridColumn aem-GridColumn--default--12">
                      <div >
                        <div className="innerpage">
                          <h2>
                            <span
                              className="content-sec-header-h2-akkurat-mono floting-nav-title fw-bold"
                              tabIndex="0"
                            >
                              What we Think
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="splitparsys aem-GridColumn aem-GridColumn--default--12">
                      <div
                        className="splitparsys "
                        style={{}}
                      >
                        <div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                                <div className="latest-thinking case-study1 aem-GridColumn aem-GridColumn--default--12">
                                  <div className="cq-dd-pages">
                                    <div className="foundation-ordered-list-container">
                                      <span className="foundation-list-item">
                                        <div className="wipro-case-studies-bg">
                                          <a
                                            className="cursor"
                                            href="/healthcare/why-value-based-cares-moment-has-finally-come-for-healthcare-providers/"
                                            target="_self"
                                          >
                                            <div className="wipro-shadow" />
                                            <img
                                              alt="Healthcare"
                                              className="multi-image"
                                              data-hd="/content/dam/nexus/en/industries/healthcare/images/why-value-based-cares-1896x275.jpg/jcr:content/renditions/why-value-based-cares-575x540.jpg"
                                              data-mob="/content/dam/nexus/en/industries/healthcare/images/why-value-based-cares-1896x275.jpg/jcr:content/renditions/why-value-based-cares-575x540.jpg"
                                              data-sd="/content/dam/nexus/en/industries/healthcare/images/why-value-based-cares-1896x275.jpg/jcr:content/renditions/why-value-based-cares-575x540.jpg"
                                              src="/think (1).png"
                                              tabIndex="0"
                                            />

                                          </a>
                                        </div>

                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="latest-thinking case-study1 aem-GridColumn aem-GridColumn--default--12">
                                  <div className="cq-dd-pages">
                                    <div className="foundation-ordered-list-container">
                                      <span className="foundation-list-item">
                                        <div className="wipro-case-studies-bg">
                                          <a
                                            className="cursor"
                                            href="/consulting/these-tech-trends-will-reshape-healthcare-in-2024/"
                                            target="_self"
                                          >
                                            <div className="wipro-shadow" />
                                            <img
                                              alt="Healthcare"
                                              className="multi-image"
                                              data-hd="/content/dam/nexus/en/service-lines/consulting/tech-trends/1896x275-tech-trends-healthcare.jpg"
                                              data-mob="/content/dam/nexus/en/service-lines/consulting/tech-trends/1896x275-tech-trends-healthcare.jpg"
                                              data-sd="/content/dam/nexus/en/service-lines/consulting/tech-trends/1896x275-tech-trends-healthcare.jpg"
                                              src="/think (3).png"
                                              tabIndex="0"
                                            />

                                          </a>
                                        </div>
                                        <div
                                          className="modal fade show-more-popup"
                                          id="poupsample"
                                          role="dialog"
                                          tabIndex="-1"
                                        >
                                          <div
                                            className="modal-dialog modal-lg"
                                            style={{
                                              maxWidth: '600px'
                                            }}
                                          >
                                            <div className="modal-content">
                                              <button
                                                className="close"
                                                data-dismiss="modal"
                                                type="button"
                                              >
                                                <img
                                                  alt="popup-close.png"
                                                  src="/content/dam/nexus/images/pop-up/popup-close.png"
                                                />
                                              </button>

                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                                <div className="latest-thinking case-study1 aem-GridColumn aem-GridColumn--default--12">
                                  <div className="cq-dd-pages">
                                    <div className="foundation-ordered-list-container">
                                      <span className="foundation-list-item">
                                        <div className="wipro-case-studies-bg">
                                          <a
                                            className="cursor"
                                            href="/business-process/how-genai-is-changing-the-game-for-healthcare-payers/"
                                            target="_self"
                                          >
                                            <div className="wipro-shadow" />
                                            <img
                                              alt="Healthcare"
                                              className="multi-image mx-3"

                                              src="/think (4).png"
                                              tabIndex="0"
                                            />

                                          </a>
                                        </div>

                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="latest-thinking case-study1 aem-GridColumn aem-GridColumn--default--12">
                                  <div className="cq-dd-pages">
                                    <div className="foundation-ordered-list-container">
                                      <span className="foundation-list-item">
                                        <div className="wipro-case-studies-bg">
                                          <a
                                            className="cursor"
                                            href="/consulting/these-tech-trends-will-reshape-healthcare-in-2024/"
                                            target="_self"
                                          >
                                            <div className="wipro-shadow" />
                                            <img
                                              alt="Healthcare"
                                              className="multi-image "
                                              data-hd="/content/dam/nexus/en/service-lines/consulting/tech-trends/1896x275-healthcare-tech-trends-new.jpg/jcr:content/renditions/575x540-healthcare-tech-trends-new.jpg"
                                              data-mob="/content/dam/nexus/en/service-lines/consulting/tech-trends/1896x275-healthcare-tech-trends-new.jpg/jcr:content/renditions/575x540-healthcare-tech-trends-new.jpg"
                                              data-sd="/content/dam/nexus/en/service-lines/consulting/tech-trends/1896x275-healthcare-tech-trends-new.jpg/jcr:content/renditions/575x540-healthcare-tech-trends-new.jpg"
                                              src="/think (2).png"
                                              tabIndex="0"
                                            />

                                          </a>
                                        </div>

                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Demo />
      </div >
    </>
  );
}


export default Healthcare