import React from 'react'

const Blogcard = () => {
    return (
        <>

            <div className="container1">
                <div className="card mt-3">
                    <div className="card-headers">
                        <img src="/blog1 (1).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h4>
                        Fault
                        Management

                        </h4>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                        <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div>
                    </div>
                </div>

                <div className="card mt-3">
                    <div className="card-headers">
                        <img src="/blog1 (2).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h4>
                            Network Infrastructure Monitoring

                        </h4>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                        <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-headers">
                        <img src="/blog1 (3).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h4>
                        Configuration Management
                        </h4>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                        <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container1'>
                <div className=" card">
                    <div className="card-headers">
                        <img src="/blog1 (4).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h4>
                        Security
                        Management
                        </h4>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                        <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div>
                    </div>
                </div>
                <div className=" card">
                    <div className="card-headers">
                        <img src="/blog1 (5).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h4>
                        Performance Management
                        </h4>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                        <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogcard